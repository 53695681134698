import axios from "axios";

// const backendUrl = "http://localhost:3000/api";
//  const backendUrl = "http://localhost:4000"
 //const backendUrl = "https://iqpathstechnology.com"
  const backendUrl = "https://backend.iqpaths.com"
const fetchCourseRepos = async () => {
  try {
    const { data } = await axios.get(`${backendUrl}/card/get-course-cards`);
    return data;
  } catch (err) {
    console.error(`Error fetching all repos: ${err}.`);
    return { isErr: true };
  }
};

const fetchMockInterviewRepos = async () => {
  try {
    const { data } = await axios.get(`${backendUrl}/card/get-mockinterview-cards`);
    return data;
  } catch (err) {
    console.error(`Error fetching all repos: ${err}.`);
    return { isErr: true };
  }
};

const fetchInternshipRepos = async () => {
  try {
    const { data } = await axios.get(`${backendUrl}/card/get-internship-cards`);
    return data;
  } catch (err) {
    console.error(`Error fetching all repos: ${err}.`);
    return { isErr: true };
  }
};

const fetchDescripton = async (slugname) => {
  try {
    const { data } = await axios.get(`${backendUrl}/description/${slugname}`);
    return data;
  } catch (err) {
    console.error(`Error fetching all repos: ${err}.`);
    return { isErr: true };
  }
};

const createOrder = async (slug, type) => {
  try {
    const { data } = await axios.post(`${backendUrl}/payment/create-order`, {
      productId: slug
    });
    return data;
  } catch (err) {
    console.error(`Error creating order: ${err}.`);
    return { isErr: true };
  }
};

const verifyPayment = async (paymentDetails, successCallback) => {
  const { data } = await axios.post(
    `${backendUrl}/payment/verify`,
    paymentDetails,
  );
  if (data.status === "success") successCallback();
};

export { fetchCourseRepos,fetchInternshipRepos,fetchMockInterviewRepos, fetchDescripton,createOrder, verifyPayment };
